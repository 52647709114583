import React from 'react'
import { Link } from 'theme-ui'
import SVG from 'react-inlinesvg'
import gatsbySVG from '../../assets/Gatsby-Logo.svg'
import unicreSVG from '../../assets/logo-uni.svg'

const styles = {
  link: {
    color: `heading`,
    fontWeight: `medium`,
    textDecoration: `none`,
    ':visited': {
      color: `heading`
    },
    ':hover': {
      color: `#663399`
    },
    svg: {
      width: 150
    }
  }
}

const PoweredByGatsby = () => (
  <Link
    target='_blank'
    title='Unicre'
    href='https://www.unicre.pt/'
    rel='noopener'
    sx={styles.link}
  >
    {unicreSVG && <SVG src={unicreSVG} />}
  </Link>
)

export default PoweredByGatsby
